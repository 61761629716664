@font-face {
  font-family: "CoreUI-Icons-Brand";
  src: url("#{$coreui-brand-icons-font-path}/CoreUI-Icons-Brand.eot?64h6xh");
  src: url("#{$coreui-brand-icons-font-path}/CoreUI-Icons-Brand.eot?64h6xh#iefix") format("embedded-opentype"),
    url("#{$coreui-brand-icons-font-path}/CoreUI-Icons-Brand.ttf?64h6xh") format("truetype"),
    url("#{$coreui-brand-icons-font-path}/CoreUI-Icons-Brand.woff?64h6xh") format("woff"),
    url("#{$coreui-brand-icons-font-path}/CoreUI-Icons-Brand.svg?64h6xh#CoreUI-Icons-Brand") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="#{$coreui-brand-icons-prefix}"],
[class*=" #{$coreui-brand-icons-prefix}"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "CoreUI-Icons-Brand" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $icon, $unicode in $brand-icons {
  .#{$coreui-brand-icons-prefix}#{$icon} {
    &:before {
      content: unicode($unicode);
    }
  }
}
