.idva {
  &-ts {
    background: #ffffff;
    position: relative;
    th {
      position: sticky;
      top: 0;
    }
    &-wrp {
      overflow: auto;
      &-r {
        position: relative;
      }
    }
    &-sticky {
      position: absolute;
      right: 0;
      top: 0;
    }
    &-ul {
      padding: 48px 0 0;
      max-height: calc(100vh - 218px);
      overflow: auto;
      width: 100%;
      &-li {
        display: flex;
        flex-wrap: wrap;
        padding: 0.75rem 0 !important;
        span {
          display: block;
          padding: 0 0.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-id {
        width: 25%;
        &.active {
          margin: -7px 0 -7px -0.75em;
        }
      }
      &-lable {
        width: 75%;
        &.active {
          margin: -7px 0;
        }
      }
      li {
        background: #ffffff;
        list-style: none;
        padding: 0.75rem;
        border-bottom: 1px solid #c8ced3;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child {
          font-weight: bold;
          cursor: default;
          position: absolute;
          top: 0;
          left: 15px;
          max-width: calc(100% - 30px);
          background: #1e986e;
          color: #ffffff;
        }
        &.active {
          background: #f1f1f1;
          color: #1e986e;
          font-weight: bold;
        }
      }
    }
    &-m {
      max-height: calc(100vh - 222px);
      transition: all ease 0.2s;
      overflow: hidden;
      &.non-active {
        max-height: 0;
      }
    }
    &-c {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 15px;
      &-r {
        width: 250px;
        transition: all ease 0.2s;
        &.collap {
          width: 50px;
          li {
            text-align: center;
          }
        }
        .idva-ts-ul {
          margin: 0;
          height: calc(100vh - 218px);
          background: #ffffff;
          li:first-child {
            left: 0;
            max-width: 100%;
          }
          li:last-child {
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 100%;
            background: #e9e9e9;
            border-bottom: none;
            text-align: right;
          }
        }
      }
      &-m {
        width: calc(100% - 280px);
        transition: all ease 0.2s;
        &.expand {
          width: calc(100% - 80px);
        }
      }
    }
  }
}

.category-ts-g {
  position: relative;
  .category {
    &-wrp {
      padding: 48px 15px 0;
      background: #ffffff;
      max-height: calc(100vh - 218px);
      overflow: auto;
    }
    &-head {
      background: #1e986e;
      color: #ffffff;
      position: absolute;
      top: 0;
      left: 15px;
      width: calc(100%);
      z-index: 2;
      &-col {
        padding: 12px 15px;
        border: 1px solid #ffffff;
      }
    }
  }
}
