.main {
  background: #f9f9f9;
}

.app-header .navbar-brand {
  img {
    width: 85%;
  }
}

.sidebar .nav-link.active {
  color: #fff;
  background: #656a6d;
}

.icon-options-rotated {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 17px !important;
  ul {
    right: 0 !important;
    left: unset !important;
  }
  &-d {
    transform: rotate(90deg);
    font-size: 12px;
    margin: 0 -3px !important;
    color: #797777;
  }
}

.idva-selection-box {
  padding: 0 !important;
  background: #efefef;
  border: 1px solid #cccccc;
  width: 35px;
  height: 100%;
  max-height: 40px;
  &.active {
    box-shadow: inset 0px 0px 20px 0px #00000033, inset 0px 0px 20px 0px #00000033;
  }
}

.idva-taskbar {
  margin: -20px auto 4px;
  &-icon {
    font-size: 20px;
    padding: 5px 10px;
    cursor: pointer;
    color: black !important;
    text-decoration: none !important;
    position: relative;
    background: #f9f9f9;
    transition: all ease 0.2s;
    border-radius: 50%;
    display: flex;
    align-items: center;
    &:hover {
      background: #eaeaea;
    }
    &-btn {
      cursor: pointer;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: unset;
      font-size: 14px;
      span {
        font-size: 13px;
        margin-left: 8px;
      }
    }
    &.disabled {
      pointer-events: none;
      color: #cccccc !important;
    }
  }
}

.inner-task-ribbon {
  .idva-taskbar-icon {
    background: #ffffff;
  }
}

.idva {
  &-flex {
    display: flex;
    &-end {
      justify-content: flex-end;
    }
  }
  &-justify {
    justify-content: space-between;
  }
  &-justify-c {
    justify-content: center;
  }
  &-align {
    align-items: center;
  }
  &-fixed-table {
    overflow: auto;
    height: calc(100vh - 204px);
    .idva-at-main-head {
      position: sticky;
      top: -0;
      z-index: 2;
    }
  }
  &-fixed-sub-table {
    overflow: auto;
    max-height: calc(100vh - 204px);
    .idva-at-sub-head {
      position: sticky;
      top: -0;
      z-index: 2;
    }
  }
}

.selected-list .c-list .c-token {
  background: pink;
}

.c-remove.clear-all {
  display: none;
}

.editing {
  margin: -10px -0.375rem;
  select.form-control {
    padding-left: 1px;
  }
  input.form-control {
    padding-left: 5px;
  }
  .selected-list .c-btn {
    padding: 0 10px;
    min-height: 32px;
    margin: -1px;
  }
  &-not {
    margin: -3px 0;
    display: flex;
    align-items: center;
  }
  &-td {
    vertical-align: middle !important;
  }
  &-tr {
    &-bg {
      background: #fdeef0 !important;
    }
  }
}

.geo {
  .editing-not {
    min-height: 35px;
    margin: -10px 0;
  }
}

.col {
  &-3\/2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  &-21\/2 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}

.cus-drop {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  &.right {
    right: 0 !important;
    left: unset !important;
  }
  &.active {
    display: block;
    inset: 100% auto auto 0px;
    transform: translateY(0px);
  }
}

.work-item {
  display: flex;
  align-items: center;
  &:hover {
    background: #f9f9f9;
  }
  &-w-h {
    font-weight: bold;
    font-size: 16px;
    padding: 5px 10px;
  }
  &-wrp {
    right: 0 !important;
    left: unset !important;
  }
  &-input {
    margin: -5px 0;
  }
  &-n {
    padding: 10px;
    width: calc(100% - 72px);
    min-width: 180px;
  }
  &-i {
    width: 36px;
    height: 36px;
    font-size: 22px;
    background: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    border-radius: 50%;
    &:hover {
      background: #eaeaea;
    }
  }
  &-l {
    padding: 10px;
    width: 160px;
  }
  &-f {
    padding: 5px;
    input,
    select {
      width: 250px;
    }
  }
  &-btn {
    margin: 5px;
    border: none;
    background: #1e986e;
    color: #ffffff;
    padding: 5px 15px;
    &.gray {
      background: #cccccc;
    }
  }
}

.dupe-check {
  &-head {
    display: flex;
  }
  &-wrp {
    padding: 8px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    width: 220px;
  }
  &-box {
    background-color: transparent;
    border: 2px solid #cccccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: block;
    position: relative;
    margin-right: 10px;
    &.active:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 8px;
      width: 6px;
      height: 14px;
      border: solid #000000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.idva-check {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &-btn {
    position: relative;
    cursor: pointer;
    margin: 0;
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #cccccc;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  &:checked + .idva-check-btn:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.idva {
  &-at {
    &-main {
      border: none;
      &-head {
        background: #1e986e;
        color: white;
        & > tr > th {
          border: 1px solid #ffffff;
          &:first-child {
            border-right: none;
          }
          &:nth-child(2) {
            border-left: none;
          }
        }
        &.second-nb {
          & > tr > th:nth-child(2) {
            border-right: none;
          }
          & > tr > th:nth-child(3) {
            border-left: none;
          }
        }
      }
      &-body-tr {
        background: #ffffff;
        border-bottom: 1px solid #cccccc;
      }
    }
    &-main-body-tr {
      &.active,
      &:hover {
        background: #f9f9f9;
      }
    }
    &-sub {
      &-wrp {
        max-height: 0;
        overflow: 0;
        background: #ffffff;
        &-td {
          padding: 0px !important;
          &-d {
            padding: 0;
            max-height: 0;
            overflow: hidden;
            transition: all ease 0.5s;
          }
        }
        &.active {
          .idva-at-sub-wrp-td-d {
            padding: 0.75rem;
            max-height: 1200px;
          }
          display: table-row;
        }
      }
      &-head {
        background: #f1f1f1;
        tr > th {
          border: 1px solid #ffffff;
          &:first-child {
            border-right: none;
          }
          &:nth-child(2) {
            border-left: none;
          }
        }
        &.second-nb {
          & > tr > th:nth-child(2) {
            border-right: none;
          }
          & > tr > th:nth-child(3) {
            border-left: none;
          }
        }
      }
      &-body {
        border-bottom: 1px solid #cccccc;
        &.active,
        &:hover {
          background: #f9f9f9;
        }
      }
      margin-bottom: 0;
      background: #ffffff;
      width: 100%;
      cursor: default;
    }
    &-m {
      padding: 9px !important;
      vertical-align: middle !important;
    }
    &-fit-img {
      width: 24px;
      margin: -8px ​0 -5px;
    }
    &-status {
      padding: 0 !important ;
      min-width: 3px;
      max-width: 4px;
      width: 3px;
      background: #e4e4e4;
      &.red {
        background: #dc8181;
      }
      &.green {
        background: #34d3a4;
      }
    }
    &-status-th {
      padding: 0 !important ;
      min-width: 3px;
      max-width: 4px;
      width: 3px;
    }
    &-acctd {
      color: #969696;
      min-width: 40px;
      max-width: 40px;
      cursor: pointer;
    }
  }
  &-tab {
    &-synopsis {
      display: flex;
      &-m {
        width: calc(100% - 300px);
      }
      &-i {
        margin-left: 20px;
        &-d {
          position: relative;
          width: 280px;
          height: 280px;
          input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}

.tgl-btn {
  outline: 0;
  display: block;
  width: 3em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  user-select: none;
  &:after,
  &:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }

  &:after {
    left: 0;
  }

  &:before {
    display: none;
  }
}

// themes
.tgl-light {
  display: none;
  + .tgl-btn {
    background: #cccccc;
    border-radius: 1.5em;
    padding: 2px;
    transition: all 0.4s ease;
    margin-bottom: 0;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
    }
  }

  &:checked + .tgl-btn {
    background: #9fd6ae;
  }
  &:checked + .tgl-btn:after {
    left: 50%;
  }
}
.tgl-radio {
  display: none;
  + .tgl-btn {
    width: 20px;
    height: 20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      width: 14px;
      height: 14px;
      background: #cccccc;
      border-radius: 50%;
      transition: all 0.2s ease;
      margin: auto;
      transform: scale(0);
    }
  }

  &:checked + .tgl-btn {
  }
  &:checked + .tgl-btn:after {
    transform: scale(1);
  }
}
.tab-content {
  max-height: calc(100vh - 130px);
  overflow: auto;
}
.confirm-modal {
  &-body {
    background: transparent !important;
    cursor: default;
    border: none !important;
  }
  &-wrp {
    background: white;
    width: 480px;
    margin: auto;
    padding: 20px 10px 10px;
  }
  &-h2 {
    text-align: center;
    font-size: 22px;
    margin: 40px;
  }
  &-b {
    &-w {
      display: flex;
      justify-content: center;
    }
    padding: 5px 15px;
    border: none;
    width: 150px;
    margin: 10px;
    color: white;
    background: #1e986e;
    &.gray {
      background: #cccccc;
    }
  }
}

.theme-bor {
  padding: 7px;
  display: block;
  width: 100%;
  min-height: 43px;
  border: 1px solid #cccccc;
}
.menu-item-pad {
  padding: 0.75rem;
  &:hover {
    background: #f9f9f9;
  }
}

.legends {
  &-cic {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    display: block;
  }
  &-head {
    margin-bottom: 15px;
  }
  &-wrp {
    display: flex;
    align-items: center;
  }
  &-red {
    background: #dc8181;
  }
  &-orange {
    background: #ffcb9f;
  }
  &-gray {
    background: #c8ced3;
  }
}
