// Here you can add other styles
.dashboard-item {
  background: #ffffff;
  border: 1px solid #cccccc;
  text-align: center;
  padding: 15px 0;
  display: block;
  img {
    margin: auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }
  h4 {
    font-size: 16px;
    color: #000000;
    margin: 8px 0 0;
  }
  &-wrp {
    margin: 15px 0;
  }
}

.modal-dialog-scrollable {
  max-width: calc(100% - 400px) !important;
  .modal-content {
    max-height: unset !important;
    background: transparent;
    border: none;
  }
  .modal-body {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #48b131;
  border-color: #48b131;
}
.enrich-drop .dropdown-list {
  max-width: 370px;
}

.ccx-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11111;
  top: 0;
  left: 0;
  &-container {
    width: 100px;
    height: 100px;
    svg {
      width: 100%;
    }
  }
}

.ccx-meesage-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%);
  z-index: 1222;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &-body {
    width: 100%;
    background: #ffffff;
    max-width: 360px;
    h3 {
      background: #f9f9f9;
      padding: 10px;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      padding: 10px;
    }
  }
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  padding-left: 24px;
}

.cid-ccxLayout {
  background-image: url(/assets/img/other/layout.svg);
}
.cid-ccxSavePost {
  background-image: url(/assets/img/other/save-post.svg);
}
.cid-ccxremoveRecord {
  background-image: url(/assets/img/other/remove-record.svg);
}

.truncate-field {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.idva-record {
  display: flex;
  width: 100%;
  overflow: auto;
  &-card {
    width: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px;
    &:last-child {
      margin-right: 0;
    }
    h2 {
      font-size: 16px;
    }
    ul {
      border: 1px solid #cccccc;
      background: #ffffff;
      list-style: none;
      padding: 0;
      margin: 0;
      height: 160px;
      li {
        border-bottom: 1px solid #cccccc;
        padding: 5px 10px;
        &:hover {
          background: #cccccc;
        }
        &.active {
          background: #cccccc;
        }
      }
    }
  }
}
